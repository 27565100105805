export default {
	country: {
		mobile: {
			accordionLink: {
				title: "Unsere Werkstatt",
				content: [
					{
						title: "Über Uns",
						link: "#ueber-uns",
					},
					{
						title: "Neuigkeiten",
						link: "#neuigkeiten",
					},
					{
						title: "Philosophie",
						link: "#philosophie",
					},
					{
						title: "Unsere Leistungen",
						link: "#leistungen",
					},
				],
			},
			normalLink: [
				{
					title: "Filialfinder",
					link: "#filialfinder",
				},
				{
					title: "Blog",
					link: "#blog",
				},
				{
					title: "Kontakt",
					link: `#kontakt`,
				},
			],
		},
		desktop: [
			{
				title: "Über uns",
				link: "#ueber-uns",
			},
			{
				title: "Filialfinder",
				link: "#filialfinder",
			},
			{
				title: "Leistungen",
				link: "#leistungen",
			},
			{
				title: "Blog",
				link: "#blog",
			},
			{
				title: "Philosophie",
				link: "#philosophie",
			},
			{
				title: "Kontakt",
				link: "#kontakt",
			},
		],
	},
	franchise: {
		mobile: {
			accordionLink: {
				title: "Unsere Werkstatt",
				content: [
					{
						title: "Über Uns",
						link: "#ueber-uns",
					},
					{
						title: "Team",
						link: "#team",
					},
					{
						title: "Neuigkeiten",
						link: "#neuigkeiten",
					},
					{
						title: "Aktion",
						link: "#aktion",
					},
					{
						title: "Philosophie",
						link: "#philosophie",
					},
				],
			},
			normalLink: [
				{
					title: "Team",
					link: "#team",
				},
				{
					title: "Kontakt",
					link: `#kontakt`,
				},
			],
		},
		desktop: [
			{
				title: "Über uns",
				link: "#ueber-uns",
			},
			{
				title: "Aktion",
				link: "#aktion",
			},
			{
				title: "Team",
				link: "#team",
			},
			{
				title: "Blog",
				link: "#blog",
			},
			{
				title: "Philosophie",
				link: "#philosophie",
			},
			{
				title: "Kontakt",
				link: "#kontakt",
			},
		],
	},
}
